.faq {
  .accordion-button, .accordion-collapse {
    background-color: $tuijo-white;
  }
  .accordion-button:not(.collapsed) {
    background-color: $tuijo-grid-line-color;
  }
}
.anchorlist {
  list-style-type: none;
  a {
    font-weight: 500;
    text-decoration: none;
    &:hover {
      border-bottom: 1px $primary solid;
    }
  }
  li {
    padding-bottom: 4px;
  }
}