.volume-meter {
	display: flex;
	align-items: flex-end;
	height: 4px;
  width: 50px;
  background-color: $light;
	border-radius: 2px;
}
.volume-level {
	width: var(--width);
	max-width: 100%;
	height: 100%;
	background: $primary;
	border-radius: 2px;
}
.volume-error {
  display: none;
  &.show {
    display: block;
  }
}