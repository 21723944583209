.navbar-vertical {
  border-bottom: 1px solid $tuijo-gray-300;
  @include media-breakpoint-up(md) {
    border-bottom-width: 0;
    border-right: 1px solid $tuijo-gray-300;
    left: 0;
    bottom: 0;
    display: block;
    max-width: $vertical-nav-width;
    overflow-y: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.navbar-vertical.navbar-expand-md>[class*=container] {
  @include media-breakpoint-up(md) {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-vertical.navbar-expand-md .navbar-brand {
  padding: 0;
  @include media-breakpoint-up(md) {
    display: block;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    text-align: center;
  }
}

.navbar-vertical.navbar-expand-md .navbar-collapse {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 4px;
  padding-right: 4px;
  @include media-breakpoint-up(md) {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    min-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
}

.navbar-collapse:before {
  content: "";
  display: block;
  border-top: 1px solid $tuijo-gray-300;
  margin: 0.75rem -1rem;
  @include media-breakpoint-up(md) {
    content: none;
  }
}

.navbar-vertical.navbar-expand-md .navbar-nav {
  @include media-breakpoint-up(md) {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.navbar-vertical.navbar-expand-md .navbar-collapse>* {
  @include media-breakpoint-up(md) {
    min-width: 100%;
  }
}

.navbar-vertical.navbar-expand-md .navbar-user {
  @include media-breakpoint-up(md) {
    border-top-style: solid;
    border-top-width: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
    order: 1000;
  }
}

.navbar-vertical.navbar-expand-md .navbar-user .dropdown .dropdown-menu {
  @include media-breakpoint-up(md) {
    bottom: 100%;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navbar-user {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top-color: $tuijo-gray-300;
  .dropdown-toggle:after {
    content: none;
  }
  @include media-breakpoint-up(md) {
    margin-top: 0.75rem;
  }
}

.navbar-vertical .navbar-nav .nav-link {
  align-items: center;
  display: flex;
  @include media-breakpoint-up(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: $tuijo-gray-700;
}
.navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-nav .nav-link .feather{
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 1rem;
}
.navbar-nav .nav-link.active {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    border-left: 2px solid $primary;
    bottom: 0.5rem;
    left: 0;
    top: 0.5rem;
  }
}

.navbar-vertical .navbar-nav .nav .nav-link {
  font-size: 0.75rem;
  padding-left: 3.125rem;
  @include media-breakpoint-up(md) {
    padding-left: 3.625rem;
  }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] .feather-chevron-down{
  transform: rotate(-180deg);
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse] .feather-chevron-down{
  transition: transform .2s;
}

.navbar-divider {
  @include media-breakpoint-down(sm) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

// Nav-tabs
.nav-overflow {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.nav-tabs .nav-item {
  margin-left: $spacer/2;
  margin-right: $spacer/2;
  &.disabled:hover {
    cursor: not-allowed;
  }
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs .nav-link {
  white-space: nowrap;
  padding: 1rem 0;
}

.nav-tabs-addable {
  border-bottom: 1px solid $border-color;
  .nav-item {
    display: flex;
    &:last-child {
      margin-right: $spacer/2;
    }
  }
  .nav-item-add-tab {
    order: 1000;
  }
  .nav-item-add-tab-btn {
    border: none;
    background: transparent;
    color: $primary;
    height: 100%;
    padding: 0;
    &:hover {
        color: $link-hover-color;
    }
  }
  .nav-item-close-btn {
    border: none;
    background: transparent;
    color: $danger;
    padding: 0;
    margin-left: 0.25em;
    &:hover {
        color: shift-color($danger, $link-shade-percentage);
    }
    .feather {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
}

.nav-overflow-wrap {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #f9fbfd;
    background: linear-gradient(90deg, rgba(249,251,253,0) 0%, rgba(249,251,253,1) 100%);
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
  }
  .nav-tabs .nav-item:last-child {
    margin-right: 50px;
  }
}