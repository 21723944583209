.table {
  table-layout: fixed;
  thead th {
    background-color: $table-striped-color;
    color: $tuijo-gray-600;
    font-size: .625rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  & > :not(:first-child) {
    border: none;
  }
  a.sort {
    text-decoration: none;
    font-weight: 700;
    .feather {
      transform: rotate(90deg);
      height: 10px;
      width: 10px;
      stroke-width: 3;
      margin-left: 0.25rem;
    }
  }
  .dropdown-toggle {
    color: $tuijo-gray-600;
    transition: $btn-transition;
    &::after{
      content: none;
    }
    .feather {
      height: 20px;
      width: 20px;
    }
    &:hover, &.show {
      color: $tuijo-body-color;
    }
  }
}
thead, tbody, tfoot, tr, td, th {
  border-color: $table-border-color;
}
td {
  font-size: .8125rem;
}
.table-spaceless {
  thead th:first-child,
  thead td:first-child,
  tbody th:first-child, 
  tbody td:first-child {
    padding-left: 0;
  }
  thead th:last-child,
  thead td:last-child,
  tbody th:last-child, 
  tbody td:last-child {
    padding-right: 0;
  }
}

table .dropdown {
  position: static; //Fix visible bug when there too less table rows. No ideal solution.
}