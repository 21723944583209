.modal-backdrop + .modal-backdrop {
  z-index: $zindex-modal;
}

.modal.show ~ .modal.show {
  z-index: $zindex-modal + 1;
}

.modal.show, .modal.show ~ .modal.show ~ .modal.show {
  z-index: $zindex-modal;
}

.modal-footer {
  padding-top: $spacer / 2;
  padding-bottom: $spacer / 2;
}