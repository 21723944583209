.header {
  margin-bottom: 2rem;
}

.header-body {
  border-bottom: 1px solid $border-color;
  padding-bottom: $spacer;
  padding-top: $spacer;
}

.header-pretitle {
  color: $tuijo-gray-600;
  letter-spacing: .08em;
  text-transform: uppercase;
}

.header-title {
  margin-bottom: 0;
}

.header-tabs {
  margin-bottom: -$spacer;
  border-bottom-width: 0;
}