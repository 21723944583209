.alerts-wrapper {
  z-index: 2000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: $grid-gutter-width * .5;
  pointer-events: none;
  @include media-breakpoint-up(md) {
    top: 0;
    bottom: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    left: $vertical-nav-width;
  }
  &:empty {
    display: none;
    padding: 0;
  }
  .alert:last-child {
    margin-bottom: 0;
  }
}
.alert-light {
  background-color: $gray-200;
  border-color: $tuijo-gray-300;
}