.login-preview-background {
  object-fit: cover;
  margin: 0;
}

.login-preview-login {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 25%;
  height: auto;
  margin: 0;
}

.login-preview-logo {
  top: 25%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 11%;
  height: 9%;
  object-fit: contain;
  margin: 0;
}