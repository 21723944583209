.accordion-delete-btn {
  border: none;
  background: transparent;
  order: 3;
  margin-left: 12px;
  color: $danger;
  &:hover {
      color: shift-color($danger, $link-shade-percentage);
  }
  .feather {
    height: 1.125rem;
    width: 1.125rem;
  }
}


