.navbar-vertical ~ .main-content {
  @include media-breakpoint-up(md) {
    margin-left: $vertical-nav-width;
  }
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
  }
}

h1, .h1 {
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: .5625rem;
}

ol, ul {
  padding-left: 1.125rem;
}

.row+.row {
  margin-top: 0;
}

.lead {
  color: $tuijo-gray-600;
  letter-spacing: 1.1;
}

.dotted-border {
  border: 1px dashed $input-border-color;
}

.text-muted {
  font-weight: 500;
}

label .feather-info {
  height: 1.125rem;
  width: 1.125rem;
  vertical-align: text-top;
}
