.sign-in {
  height: 100vh;
  .fade-out {
    max-height: 300px;
  }
}
.sign-in-background {
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.joining .sign-in {
  .sign-in-left-col {
    width: 100%;
  }
  .sign-in-right-col {
    width: 0%;
  }
  .sign-in-left-col, .sign-in-right-col {
    transition: width 0.8s ease-in-out;
  }
  .fade-out {
    transition: opacity 0.5s ease-in-out, max-height 0.8s ease-in-out;
    opacity: 0;
    height: 100%;
    max-height: 0;
  }
}
