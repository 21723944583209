$tuijo-background: #f9fbfd;

$tuijo-white: #ffffff;
$tuijo-gray-300: #e3ebf6;
$tuijo-gray-400: #d2ddec;
$tuijo-gray-500: #b1c2d9;
$tuijo-gray-600: #95aac9;
$tuijo-gray-700: #6e84a3;
$tuijo-gray-800: #3b506c;
$tuijo-gray-900: #283e59;
$tuijo-primary-100: #BFCDDB;
$tuijo-primary-300: #809BB7;
$tuijo-primary-700: #013770;
$tuijo-default-color: #95aac9;
$tuijo-default-font-color: #95aac9;
$tuijo-arc-border-color: #fff;
$tuijo-arc-hover-border-color: #fff;
$tuijo-grid-line-color: #e3ebf6;
$tuijo-body-color: #12263f;

$tuijo-secondary-100: #BFE6E8;
$tuijo-secondary-300: #7FCDD1;
$tuijo-secondary-700: #009BA4;

$tuijo-tertiary-100: #F9CBDA;
$tuijo-tertiary-300: #F398B6;
$tuijo-tertiary-700: #E8316D;

$gray-200: #edf2f9;

$body-color: $tuijo-body-color;
$border-color: $tuijo-gray-300;

$primary: $tuijo-primary-700;
$light: $gray-200;

$font-size-base: 0.9375rem;

$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
) !default;

$enable-rfs: false;
$enable-negative-margins: true;

$vertical-nav-width: 300px;

$body-bg: $tuijo-background;

$h1-font-size: 1.5rem;
$h2-font-size: 1.125rem;
$h3-font-size: 1.0625rem;
$h4-font-size: 0.9375rem;
$h5-font-size: 0.8125rem;
$h6-font-size: 0.625rem;

$headings-margin-bottom: 1.125rem;
$headings-line-height: 1.1;

$lead-font-size: $font-size-base;
$lead-font-weight: 500;

$small-font-size: 0.8em;

$text-muted: $tuijo-gray-600;

$hr-margin-y: 1rem;
$hr-color: $border-color;
$hr-opacity: 1;

$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-striped-color: #f9fbfd;
$table-th-font-weight: 500;
$table-border-color: $border-color;

$card-border-color: $border-color;
$card-cap-bg: $tuijo-white;

$navbar-light-color: $tuijo-gray-700;
$navbar-light-hover-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-light-disabled-color: $tuijo-gray-600;
$navbar-light-toggler-border-color: transparent;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-light-toggler-border-color: transparent;
$navbar-toggler-focus-width: 0;

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: 400;
$nav-link-color: $tuijo-default-font-color;
$nav-link-hover-color: $tuijo-gray-700;
$nav-link-disabled-color: $tuijo-gray-400;

$nav-tabs-border-color: transparent;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent transparent $tuijo-primary-700;

$dropdown-link-active-color: $tuijo-white !important;

$input-font-weight: 400;
$input-border-radius: 4px;
$input-bg: $tuijo-white;
$input-border-color: $tuijo-primary-100;
$input-placeholder-color: $tuijo-primary-100;
$form-label-font-weight: 500;

$btn-padding-y-lg: 0.625rem;
$btn-font-size-lg: $font-size-base;
$btn-border-radius-lg: 6px;

$modal-content-border-color: $border-color;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $border-color;
$modal-backdrop-bg: $tuijo-body-color;

$modal-header-padding-y: $spacer / 2;

$alert-padding-y: $spacer/4*3;

$accordion-bg: $tuijo-white;

$feather-icon-mic-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23013770' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off'%3E%3Cline x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3Cpath d='M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6'%3E%3C/path%3E%3Cpath d='M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='23'%3E%3C/line%3E%3Cline x1='8' y1='23' x2='16' y2='23'%3E%3C/line%3E%3C/svg%3E");
$feather-icon-mic-off-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23013770' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off'%3E%3Cline x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3Cpath d='M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6'%3E%3C/path%3E%3Cpath d='M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='23'%3E%3C/line%3E%3Cline x1='8' y1='23' x2='16' y2='23'%3E%3C/line%3E%3C/svg%3E");
$feather-icon-video-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23013770' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off'%3E%3Cline x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3Cpath d='M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6'%3E%3C/path%3E%3Cpath d='M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='23'%3E%3C/line%3E%3Cline x1='8' y1='23' x2='16' y2='23'%3E%3C/line%3E%3C/svg%3E");
$feather-icon-video-off-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23013770' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off'%3E%3Cline x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3Cpath d='M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6'%3E%3C/path%3E%3Cpath d='M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='23'%3E%3C/line%3E%3Cline x1='8' y1='23' x2='16' y2='23'%3E%3C/line%3E%3C/svg%3E");
$feather-icon-settings-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23013770' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off'%3E%3Cline x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3Cpath d='M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6'%3E%3C/path%3E%3Cpath d='M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='23'%3E%3C/line%3E%3Cline x1='8' y1='23' x2='16' y2='23'%3E%3C/line%3E%3C/svg%3E");


// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

// <svg xmlns="httpSS://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic-off"><line x1="1" y1="1" x2="23" y2="23"></line><path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path><path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>
