.feather-icon {
  height: 24px;
  width: 24px;
}
.feather-icon-mic {
  background-image: $feather-icon-mic-bg;
  &.feather-icon-off {
    background-image: $feather-icon-mic-off-bg;
  }
}
.feather-icon-video {
  background-image: $feather-icon-video-bg;
  &.feather-icon-off {
    background-image: $feather-icon-video-off-bg;
  }
}
.feather-icon-settings {
  background-image: $feather-icon-settings-bg;
}