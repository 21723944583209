.avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  width: 3rem;
}

.avatar-name {
  margin-left: $spacer/2;
}

.navbar .avatar-name {
  font-weight: 500;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.avatar-video {
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
}