.hotspot-image {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}

.hotspot {
  position: absolute;
  height: 24px;
  width: 24px;
  transform: translate(-50%,-50%);
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  color: $tuijo-white;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: $tuijo-white;
    &:after {
      transform: scale(1.4,1.4);
    }
  }
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 100%; 
    background-color: $primary;
    position: absolute;
    z-index: -1;
    transition: transform 0.15s;
  }
  &.hotspot-secondary:after {
    background-color: $tuijo-secondary-700;
  }
  &.hotspot-tertiary:after {
    background-color: $tuijo-tertiary-700;
  }
}