.card {
  margin-bottom: $spacer;
  box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,0.03);
  .card-table {
    margin-bottom: 0;
    tbody td:first-child, 
    thead th:first-child {
      padding-left: $spacer;
    }
    tbody td:last-child, 
    thead th:last-child {
      padding-right: $spacer;
    }
  }
}
.card-fill {
  height: calc(100% - #{$spacer});
}
.card-search {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  .input-group {
    .form-control {
      padding-left: 0;
      padding-right: 0;
      border: none;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: $tuijo-gray-600;
        font-weight: 400;
      }
    }
    .input-group-text {
      padding-left: 0;
      background-color: transparent;
      border: none;
    }
  }
  .feather {
    height: 15px;
    width: 15px;
    color: $tuijo-gray-700;
  }
}
.card-icon {
  height: 2.5rem;
  width: 2.5rem;
  stroke: $primary;
  stroke-width: 1.5;
}
.card-footer {
  .pagination {
    li {
      @extend .page-item;
      a {
        @extend .page-link;
      }
    }
  }
}